@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: #E9ECEF;
}

::-webkit-scrollbar {
    display: none;
  }

  :root {
    --accent-color-h: '358';
    
    --accent-color-s: '69.3%';
    
    --accent-color-l: '37.1%';
    
    --secondaryaccent-color-h: '358';
    
    --secondaryaccent-color-s: '69.3%';
    
    --secondaryaccent-color-l: '37.1%';
  }


  @keyframes slide-right {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .animate-slide-right {
    animation: slide-right 0.5s ease-in-out;
  }

  @keyframes slide-left {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .animate-slide-left {
    animation: slide-left 0.5s ease-in-out;
  }

  ::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}